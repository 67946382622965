import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const PrivacyPolicy = () => {
  useEffect(() => {
    window.location.replace(
      'https://app.termly.io/document/privacy-policy/8eb65c3e-e605-4c9c-954d-49bb1b67e561'
    )
  }, [])

  return null
}

export default PrivacyPolicy
